<template>
  <div class="">
    <div class="rounded-xl shadow-xl bg-white p-6 flex">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">预约时间</h1>
        <p class="mt-2 text-sm text-gray-700">
          管理所有预约的时间，包括取消、设置预约
        </p>
      </div>
    </div>
    <div class="rounded-xl shadow-xl bg-white p-6 flex mt-4">
      <el-form :inline="true">
        <el-form-item label="日期">
          <el-date-picker
            v-model="searchForm.date"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :clearable="false"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handleCreateBooking"
            >全天预约</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleCancelBooking">取消全天</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="rounded-xl shadow-xl bg-white p-6 flex mt-4">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="预约日期">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.date }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预约时刻">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.hour }} 点</span>
          </template>
        </el-table-column>
        <el-table-column label="预约状态">
          <template slot-scope="scope">
            <span style="margin-left: 10px">
              <el-tag type="success" v-if="scope.row.appointment === 'YES'"
                >已预约</el-tag
              >
              <el-tag type="wrning" v-else>未预约</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="昵称">
          <template slot-scope="scope">
            {{ scope.row.account ? scope.row.account.nickName : "-" }}
          </template>
        </el-table-column>
        <el-table-column label="邮箱">
          <template slot-scope="scope">
            {{ scope.row.account ? scope.row.account.email : "-" }}
          </template>
        </el-table-column>
        <el-table-column label="电话">
          <template slot-scope="scope">
            {{ scope.row.account ? scope.row.account.mobile : "-" }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.appointment === 'YES'"
              size="mini"
              type="danger"
              @click="handleCancelHour(scope.row)"
              >取消
            </el-button>
            <el-button v-else size="mini" @click="handleCreateHour(scope.row)"
              >预约
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script type="text/javascript">
import dayjs from "dayjs";
export default {
  data() {
    return {
      loading: false,
      searchForm: {
        date: dayjs().format("YYYY-MM-DD"),
      },
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    // 查询表格数据
    getList() {
      this.loading = true;
      this.$cloud
        .post("appointment/admin/day", { ...this.searchForm })
        .then((res) => {
          if (res) {
            this.tableData = res;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    // 预约当前设置的日期
    handleCreateBooking() {
      this.$confirm(`确定预约${this.searchForm.date}这天吗？`, "预约提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post("appointment/admin_set_apply_day", {
            date: this.searchForm.date,
          })
          .then(() => {
            this.loading = false;
            this.$message.success("预约成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },
    // 取消预约当前设置的日期
    handleCancelBooking() {
      this.$confirm(
        `确定取消${this.searchForm.date}这天的预约吗？`,
        "取消预约提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$cloud
          .post("appointment/admin_cancel_day", {
            date: this.searchForm.date,
          })
          .then(() => {
            this.loading = false;
            this.$message.success("取消成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },

    // 确定预约时刻
    handleCreateHour(data) {
      this.$confirm(
        `确定设置${data.date} ${data.hour}时为已预约吗?`,
        "预约提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$cloud
          .post("appointment/admin_set_apply", {
            date: this.searchForm.date,
            hour: data.hour,
          })
          .then(() => {
            this.loading = false;
            this.$message.success("预约成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err);
            this.loading = false;
          });
      });
    },

    // 取消预约时刻
    handleCancelHour(data) {
      this.$confirm(
        `确定取消${data.date} ${data.hour}时的预约吗?`,
        "取消预约提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$cloud
          .post("appointment/admin_cancel_hour", {
            date: this.searchForm.date,
            hour: data.hour,
          })
          .then((res) => {
            this.loading = false;
            this.$message.success("取消预约成功！");
            this.getList();
          })
          .catch((err) => {
            console.log("err", err);
            this.$message.error(err.msg);
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss"></style>
